import React from 'react';
import styled from 'styled-components';

const BackgroundContainer = styled.div`
  background: ${({ backgroundImage }) =>
    backgroundImage
      ? `linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${backgroundImage})`
      : 'none'};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: ${({ styles }) => (styles.height ? styles.height : '100vh')};
  position: absolute;
  width: 100vw;
  z-index: -2;
  top: 0;
  background-size: ${({ styles }) => styles.backgroundSize};
`;

const BackgroundImg = ({
  image: { url, alt },
  styles = {},
  attributionPosition,
  children,
  ...props
}) => {
  return (
    <BackgroundContainer {...props} styles={styles} backgroundImage={url}>
      {children}
    </BackgroundContainer>
  );
};
export default BackgroundImg;
