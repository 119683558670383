import styled from 'styled-components';
import { Link } from 'gatsby-plugin-intl';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const ButtonBase = `
  color: white;
  border: 1px solid;
  border-color: transparent;
  outline: none !important;
  cursor: pointer;
  transition: all 0.125s ease-out;
  white-space: nowrap;
  font-weight: 400;
  text-decoration: none;
`;

export const Button = styled.button`
  ${ButtonBase}

  background-color: ${({ color, theme }) =>
    color ? theme.palette[color].main : theme.palette.primary.main};
  padding: ${({ size }) => (size === 'small' ? '10px 14px' : '12px 16px')};
  font-size: ${({ size }) => (size === 'small' ? '14px' : '16px')};
  border-radius: ${({ theme }) => theme.borders.radius};

  &:hover {
    background-color: ${({ theme, color }) =>
      color ? theme.palette[color].dark : theme.palette.primary.dark};
  }
`;

export const OutlinedButton = styled(Button)`
  background-color: white;
  color: ${({ theme }) => theme.palette.primary.main};
  border-color: ${({ theme, noBorder }) =>
    !noBorder && theme.palette.primary.main};

  &:hover {
    color: ${({ color }) => (color === 'grey' ? 'black' : 'white')};
    background-color: ${({ theme, color }) =>
      color ? theme.palette[color].dark : theme.palette.primary.dark};
  }
`;

export const TextOnlyButton = styled(Button)`
  color: ${({ theme }) => theme.palette.text.primary};
  border-color: transparent;
  background-color: transparent;

  &:hover {
    color: white;
  }
`;

export const ButtonLink = styled(Link)`
  ${ButtonBase}
  background-color: ${({ theme }) => theme.palette.primary.main};
  padding: ${({ size }) => (size === 'small' ? '10px 14px' : '12px 16px')};
  border-radius: ${({ theme }) => theme.borders.radius};
  font-size: ${({ size }) => (size === 'small' ? '14px' : '16px')};

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.dark};
  }
`;

export const AnchorButton = styled(AnchorLink)`
  ${ButtonBase}

  position: absolute;
  bottom: 30%;
  background-color: ${({ theme, color }) =>
    color ? theme.palette[color].main : theme.palette.primary.main};
  padding: ${({ size }) => (size === 'small' ? '10px 14px' : '12px 16px')};
  border-radius: ${({ theme }) => theme.borders.radius};
  font-size: ${({ size }) => (size === 'small' ? '14px' : '16px')};

  &:hover {
    background-color: ${({ theme, color }) =>
      color ? theme.palette[color].dark : theme.palette.primary.dark};
  }
`;
