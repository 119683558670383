import { Cookies as ReactCookies } from 'react-cookie';

const getExpirationDate = (date = null, expiringDays = 365) => {
  const expireDate = date ? new Date(date) : new Date();
  expireDate.setTime(expireDate.getTime() + expiringDays * 24 * 60 * 60 * 1000);
  return expireDate;
};

export default class Cookies {
  constructor() {
    this.cookies = new ReactCookies();
  }

  get(cookie, isBool) {
    if (isBool) {
      return this.cookies.get(cookie) === '1';
    }

    return this.cookies.get(cookie);
  }

  set(cookie, value, isBool, cookieExpiration) {
    if (isBool) {
      value = value === true ? 1 : 0;
    }

    this.cookies.set(cookie, value, {
      expires: cookieExpiration || getExpirationDate(),
    });
  }

  remove(cookie) {
    return this.cookies.remove(cookie);
  }
}
