import React from 'react';
import styled from 'styled-components';

const Dot = styled.div`
  position: relative;
  height: 4px;
  width: 50%;
  background: ${({ theme }) => theme.palette.grey[400]};
  margin: 0 4px;
  outline: none;
  box-shadow: none;
  border: none;
  cursor: pointer;
  border-radius: calc(${({ theme }) => theme.borders.radius} / 2);
  transition: width 0.125s ease-out;

  ${({ active, theme, primary }) =>
    active &&
    `
    &:before {
      content: '';
      left: 0;
      position: absolute;
      height: 6px;
      width: 100%;
      background: ${theme.palette.primary.main};
      border-radius: calc(${({ theme }) => theme.borders.radius} / 2);
    }
  `}
`;

const SlideDot = ({ onClick, primary, ...rest }) => {
  const { active, childCount } = rest;

  return (
    <Dot
      active={active}
      primary={primary}
      onClick={onClick}
      style={childCount && { width: 100 / childCount + '%' }}
    />
  );
};

export default SlideDot;
