import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  position: absolute;
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  border-top: 2px solid #f3f3f3;
  border-right: 2px solid #f3f3f3;
  border-bottom: 2px solid #f3f3f3;
  border-left: 2px solid ${({ theme }) => theme.palette.primary.main};
  background: transparent;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
`;

export const Loader = () => {
  return <Spinner />;
};

export default Loader;
