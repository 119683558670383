import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

export const BodyLarge = styled.p`
  font-size: 18px;

  ${media.lg`
    font-size: 20px;
  `}
`;

export const Highlight = styled.h6`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.secondary.main};
  margin-bottom: 8px;
`;
