import React, { Fragment } from 'react';
import styled from 'styled-components';
import wordmark from 'src/images/wordmark.png';
import { Link, useIntl } from 'gatsby-plugin-intl';

const Container = styled.footer`
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 16px;
  padding-top: 16px;
  border-top: 5px solid ${({ theme }) => theme.palette.primary.main};
`;

const Content = styled.div`
  position: relative;
  z-index: 1;
`;

const Logo = styled.img`
  width: 160px;
`;

const Copyright = styled.div`
  font-size: 14px;
  margin-top: 4px;
`;

const Links = styled.div`
  display: flex;
  justify-content: center;
  padding: 24px 0 0;
`;

const FooterLink = styled(Link)`
  color: ${({ theme }) => theme.palette.grey[700]};
  padding: 0 16px;
  text-decoration: none !important;
  font-weight: 400;

  &:visited {
    color: ${({ theme }) => theme.palette.grey[700]};
  }

  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const footer_menu = [
  {
    route: 'disclaimer',
  },
  {
    route: 'data-privacy',
  },
];

const Footer = () => {
  const year = new Date().getFullYear();

  const translate = (id) => {
    return useIntl().formatMessage({ id });
  };

  return (
    <Container>
      <Content>
        <Logo src={wordmark} />
        <Copyright>&copy; {year} Riskeeper AG</Copyright>
        <Links>
          <div>
            {footer_menu.map(({ route }, index) => {
              return (
                <Fragment key={route}>
                  <FooterLink key={index + 'FooterLink'} to={`/${route}`}>
                    {translate(`Layout.Footer.${route}`)}
                  </FooterLink>
                </Fragment>
              );
            })}
          </div>
        </Links>
      </Content>
    </Container>
  );
};

export default Footer;
