import React from 'react';
import styled from 'styled-components';
import i18n from 'src/intl/config/i18n';
import { changeLocale, IntlContextConsumer } from 'gatsby-plugin-intl';

const Container = styled.div`
  margin-right: 24px;
`;

const StyledLanguageButton = styled.button`
  background: none;
  color: ${({ inverted }) => (inverted ? 'black' : 'white')};
  border: none;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    font-weight: 500;
    margin-left: -1.5px;
  }
`;

const options = Object.entries(i18n).map(([key, { path, label }]) => ({
  value: path,
  label,
}));

const LanguageSelector = ({ inverted }) => {
  const translate = (value) => {
    const updateLang = options.filter((item) => item.value !== value)[0].value;
    changeLocale(updateLang);
  };

  return (
    <IntlContextConsumer>
      {({ language: currentLocale }) => {
        return (
          <Container>
            <StyledLanguageButton
              inverted={inverted}
              onClick={() => translate(currentLocale)}
            >
              {options.filter((item) => item.value !== currentLocale)[0].label}
            </StyledLanguageButton>
          </Container>
        );
      }}
    </IntlContextConsumer>
  );
};

export default LanguageSelector;
