import React from 'react';
import styled from 'styled-components';
import { Col, Container, media, Row } from 'styled-bootstrap-grid';
import chess from 'src/images/chess.jpg';
import { useIntl } from 'gatsby-plugin-intl';
import { AnchorButton } from '../Shared/Buttons';

export const HomeContainer = styled('div')`
  height: 100vh;
  color: white;
  position: relative;
  overflow: visible;
  background: ${({ backgroundImage }) =>
    backgroundImage
      ? `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${backgroundImage})`
      : 'none'};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;

  ${media.xs`
    height: 90vh;
  `}

  ${media.lg`
    overflow: hidden;
  `}
`;

export const TextContainer = styled(Container)`
  display: flex;
  height: 100%;
  align-items: center;
  position: relative;
`;

const IntroSlice = ({ data: { title, button } }) => {
  const translate = (id) => {
    return useIntl().formatMessage({ id });
  };

  return (
    <HomeContainer backgroundImage={chess}>
      <TextContainer>
        <Row>
          <Col md={8} xs={12}>
            <h1>{title}</h1>
          </Col>
        </Row>
        <AnchorButton
          to={`/${translate('language')}/#products`}
          color="warning"
        >
          {button}
        </AnchorButton>
      </TextContainer>
    </HomeContainer>
  );
};

export default IntroSlice;
