import React, { Fragment } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';
import Footer from './Footer';
import Header from './Header';
import shareImage from 'src/images/riskeeper_balloon.jpg';
import { CookieBanner } from 'components/Shared';

export default (props) => (
  <StaticQuery
    query={graphql`
      query SiteQuery {
        site {
          siteMetadata {
            title
            description
            banner
            url
          }
        }
      }
    `}
    render={(data) => <Layout data={data} {...props} />}
  />
);

const Layout = ({ data, children, headerInverted, tabTitlePrefix }) => {
  // Define the meta title and description
  const { title, description, url } = data.site.siteMetadata;

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <html lang="en-us" />
        <title>{tabTitlePrefix ? tabTitlePrefix + ' | ' + title : title}</title>
        <meta name="description" content={description} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <meta name="image" content={shareImage} />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={shareImage} />
      </Helmet>
      <Header inverted={headerInverted} />
      <main>{children}</main>
      <CookieBanner />
      <Footer />
    </Fragment>
  );
};
