module.exports = {
  'de-de': {
    path: 'de',
    locale: 'de-de',
    label: 'Deutsch',
    flag: 'de',
    siteLanguage: 'de',
    ogLang: 'de_DE',
  },
  'en-us': {
    path: 'en',
    default: true,
    locale: 'en-us',
    label: 'English',
    flag: 'de',
    siteLanguage: 'en',
    ogLang: 'en_US',
  },
};
