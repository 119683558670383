import React from 'react';
import styled from 'styled-components';
import { Container as Grid, media } from 'styled-bootstrap-grid';

export const Container = styled.section`
  position: relative;
  overflow: hidden;
  padding: 80px 0 32px 0;
  & > * > * + * {
    padding: 16px 0px;
  }

  ${({ theme }) => `
    background: ${theme.palette.grey[100]};
  `}

  ${media.lg`
    padding: ${({ first }) => (first ? '120px 0 80px 0' : '40px 0 80px 0')};
  `}
`;

export const SliceContainer = ({ sectionId, first, children }) => (
  <Container id={sectionId} first={first}>
    <Grid>{children}</Grid>
  </Container>
);

export const Headline = styled.h2`
  font-size: 32px;

  ${media.lg`
    font-size: 44px;
  `}
`;

export const TextContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled.h4`
  padding: 16px 0 16px;
  margin-bottom: 0px;
  ${media.lg`
    padding: 32px 0;
  `}
`;
