import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button, TextOnlyButton } from './Buttons';
import Cookies from 'src/utils/Cookies';
import Switch from 'react-switch';
import { useTheme } from 'src/hooks';
import { Container, media } from 'styled-bootstrap-grid';
import { useIntl } from 'gatsby-plugin-intl';

const cookies = new Cookies();
const ACCEPTED_ALL = 'st_cookie_consent_all';
const STATISTICS_CONSENT = 'st_statistics_consent';

const onStatisticsChanged = (granted) => {
  if (!window || !window.gtag) return;

  window.gtag('consent', 'update', {
    analytics_storage: granted ? 'granted' : 'denied',
  });
};

const StyledContainer = styled(Container)`
  display: flex;

  ${media.xs`
    flex-direction: column;
  `}
`;

const Banner = styled.div`
  position: fixed;
  display: ${({ open }) => (open ? 'block' : 'none')};
  width: 100%;
  bottom: 0;
  left: 0;
  background: #fff;
  padding: 16px;
  z-index: 10;
  box-shadow: ${({ theme }) => theme.shadows.card};

  ${media.xs`
    padding: 16px 0;
  `}
`;

const Content = styled.div`
  width: 75%;

  ${media.xs`
    width: 100%;
  `}
`;

const Headline = styled.h6`
  font-size: 14px;
  margin-bottom: 8px;
`;

const Text = styled.div`
  font-size: 12px;

  p {
    margin-bottom: 8px;
  }
`;

const Switches = styled.div`
  display: flex;

  ${media.xs`
    flex-direction: column;
  `}
`;

const Label = styled.label`
  font-size: 12px;
  font-weight: 600;
  margin-right: 16px;
`;

const FormControl = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 16px;

  &:first-of-type {
    margin-left: 0;
  }

  ${media.xs`
    margin-left: 0;
    margin-top: 8px;
  `}
`;

const Actions = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;

  ${media.xs`
    margin-top: 16px;
    justify-content: space-between;
  `}
`;

const CookieBanner = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const translate = (id) => {
    return useIntl().formatMessage({ id });
  };

  const config = [
    {
      id: 'necessary',
      disabled: true,
      label: translate(`Layout.Cookies.necessary`),
    },
    {
      id: 'statistics',
      label: translate(`Layout.Cookies.statistics`),
      cookie: STATISTICS_CONSENT,
      onAccepted: () => onStatisticsChanged(true),
    },
  ];

  const [formState, setFormState] = useState({});

  const onAccepted = (acceptAll) => {
    Object.keys(formState).forEach((id) => {
      const { disabled, cookie, onAccepted } = config.find((c) => c.id === id);
      const checked = acceptAll ? true : formState[id];

      if (disabled) return;

      cookies.set(cookie, checked, true);

      if (checked) onAccepted();
    });

    cookies.set(ACCEPTED_ALL, acceptAll, true);
    setOpen(false);
  };

  useEffect(() => {
    const cookieState = config.reduce((state, item) => {
      const accepted = cookies.get(item.cookie, true);
      if (accepted && item.onAccepted) item.onAccepted();

      return {
        ...state,
        [item.id]: item.disabled || accepted,
      };
    }, {});
    const open = !cookies.get(ACCEPTED_ALL, true);

    setFormState(cookieState);
    setOpen(open);
  }, []); // eslint-disable-line

  return (
    <Banner open={open}>
      <StyledContainer>
        <Content>
          <Headline>{translate(`Layout.Cookies.disclaimerTitle`)}</Headline>
          <Text
            dangerouslySetInnerHTML={{
              __html: translate(`Layout.Cookies.disclaimerText`),
            }}
          />
          <Switches>
            {config.map(({ disabled, label, id }) => {
              return (
                <FormControl key={id}>
                  <Label>{label}</Label>
                  <Switch
                    onColor={theme.palette.primary.main}
                    height={18}
                    width={36}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    checked={formState[id] || false}
                    disabled={disabled}
                    onChange={(checked) =>
                      setFormState({
                        ...formState,
                        [id]: checked,
                      })
                    }
                  />
                </FormControl>
              );
            })}
          </Switches>
        </Content>
        <Actions>
          <span style={{ marginRight: '8px' }}>
            <TextOnlyButton size="small" onClick={() => onAccepted(false)}>
              {translate(`Layout.Cookies.acceptNecessary`)}
            </TextOnlyButton>
          </span>
          <Button size="small" onClick={() => onAccepted(true)}>
            {translate(`Layout.Cookies.acceptAll`)}
          </Button>
        </Actions>
      </StyledContainer>
    </Banner>
  );
};

export default CookieBanner;
