import React from 'react';
import PropTypes from 'prop-types';
import { Button } from './Buttons';
import Loader from './Loader';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  overflow: hidden;
`;

const ProgressContainer = styled(({ visible, ...props }) => <div {...props} />)(
  ({ visible, theme }) => {
    let styles = {
      position: 'absolute',
      marginLeft: 'auto',
      marginRight: 'auto',
      left: '0',
      right: '0',
      opacity: 0,
      transform: 'translateY(30px)',
      transition: 'transform 0.125s ease-in',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      '& > *': {
        color: theme.palette.text.primary,
      },
    };

    if (visible) {
      styles = {
        ...styles,
        opacity: 1,
        transform: 'translateY(0)',
      };
    }

    return styles;
  }
);

const Label = styled.span`
  ${({ theme, visible }) => {
    let styles = {
      transition: 'all 0.125s ease-in',
      opacity: 1,
      transform: 'translateY(0)',
    };

    if (!visible) {
      styles = {
        ...styles,
        opacity: 0,
        transform: 'translateY(-30px)',
      };
    }

    return styles;
  }}
`;

const LoadingButton = ({
  children,
  loading,
  disabled,
  onClick,
  type,
  ...rest
}) => {
  return (
    <StyledButton
      type={type}
      onClick={onClick}
      disabled={loading || disabled}
      {...rest}
    >
      <ProgressContainer visible={loading}>
        <Loader />
      </ProgressContainer>
      <Label visible={!loading}>{children}</Label>
    </StyledButton>
  );
};

LoadingButton.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default LoadingButton;
