import React, { useState } from 'react';
import wordmark from 'src/images/wordmark.png';
import wordmark_inverted from 'src/images/wordmark_inverted.png';
import styled, { css } from 'styled-components';
import { Container, media } from 'styled-bootstrap-grid';
import { Button } from 'components/Shared';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { LanguageSelector } from 'components/Shared';
import { useIntl, Link } from 'gatsby-plugin-intl';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const NavLinkBase = css`
  text-decoration: none;
  font-weight: 400;
  align-self: center;
  width: 100%;
  text-align: center;
  padding: 12px 12px 4px 12px;
  cursor: pointer;
  font-size: 14px;

  ${media.lg`
    margin-left: 24px;
    width: auto;
  `}
`;

const NavLink = styled(Link)`
  ${NavLinkBase};
  margin-bottom: 5px;
  max-width: 60px;
`;

const SmootScrollLink = styled(AnchorLink)`
  ${NavLinkBase};
  color: ${({ invert }) => (invert ? 'black' : 'white')};
  display: none;

  &:hover {
    margin-bottom: -3px;
    border-bottom: 3px solid
      ${({ theme, invert }) => (invert ? theme.palette.primary.main : 'white')};
  }

  ${media.lg`
    position: static;
    left: auto;
    transform: none;
    padding: 10px 14px;
    display: block;
  `}
`;

export const OutlinedButtonInverted = styled(Button)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${({ active, color, theme }) =>
    active ? theme.palette[color].main : 'transparent'};
  border-color: white;
  color: white;
  padding: 8px 12px;
  display: none;

  ${({ black, active, theme }) =>
    black &&
    !active &&
    `
    border-color: ${theme.palette.text.primary};
    color: ${theme.palette.text.primary};
  `}

  ${media.md`
    position: static;
    left: auto;
    transform: none;
    padding: 10px 14px;
    display: block
  `}
`;

const Header = styled.div`
  transition: all 0.125s ease-out;
  z-index: 1000;
  font-size: 18px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  position: fixed;
  width: 100%;
  height: 60px;
  background-color: transparent;
  box-shadow: none;

  .nav-link {
    color: #fff;
    &:hover {
      margin-bottom: 4px;
      border-bottom: 2px solid white;
    }
  }

  ${media.lg`
    height: 80px;

    ${({ active }) =>
      active &&
      `height: 60px;
    `}
  `}

  ${({ inverted, theme }) =>
    inverted &&
    `
    border-bottom: none;

    .nav-link {
      color: ${theme.palette.text.primary};
      
       &:hover {
        margin-bottom: 4px;
        border-bottom: 3px solid ${theme.palette.primary.main};
      }
    }
  `}

  ${({ active, theme }) =>
    active &&
    `
    background-color: #fff;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.1);

    .nav-link {
      color: ${theme.palette.text.primary};
      
      &:hover {
        margin-bottom: 3px;
        border-bottom: 3px solid ${theme.palette.primary.main};
      }
    }
  `}
`;

const NavContainer = styled(Container)`
  display: flex;
  align-items: center;
  height: 100%;
`;

const NavLinks = styled.div`
  position: relative;
  background: transparent;
  flex-direction: row;
  width: 100%;
  transform: none;
  z-index: 0;
  display: flex;
  justify-content: center;
  flex-grow: 1;
`;

const NavActions = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;

  ${media.lg`
    justify-content: center;
  `}
`;

const Logo = styled.img`
  width: 160px;
`;

const LogoLink = styled(Link)`
  line-height: 1;
`;

const navLinks = [
  {
    nav_link: 'products',
    downScroll: true,
  },
  {
    nav_link: 'projects',
    downScroll: true,
  },
  {
    nav_link: 'about',
    downScroll: true,
  },
  {
    nav_link: 'faq',
  },
];

const HeaderNavigation = ({ inverted }) => {
  const [didScroll, setDidScroll] = useState(false);
  const invert = inverted || didScroll;

  useScrollPosition(
    ({ currPos }) => setDidScroll(currPos.y < 0),
    [setDidScroll],
    false,
    false,
    200
  );

  const translate = (id) => {
    return useIntl().formatMessage({ id });
  };

  return (
    <Header active={invert}>
      <NavContainer>
        <LogoLink to="/">
          <Logo src={invert ? wordmark : wordmark_inverted} alt-Logo />
        </LogoLink>
        <NavActions>
          <NavLinks>
            {navLinks.map(({ nav_link, downScroll }) => {
              if (downScroll) {
                return (
                  <SmootScrollLink
                    key={nav_link}
                    to={`/${translate('language')}/#${nav_link}`}
                    invert={invert}
                    title={translate(`Layout.Header.${nav_link}`)}
                  />
                );
              } else {
                return (
                  <NavLink
                    key={nav_link}
                    className="nav-link"
                    activeStyle={{ fontWeight: 600 }}
                    to={`${nav_link === 'home' ? '/' : `/${nav_link}`}`}
                  >
                    {translate(`Layout.Header.${nav_link}`)}
                  </NavLink>
                );
              }
            })}
          </NavLinks>
          <LanguageSelector inverted={invert} />
          <OutlinedButtonInverted
            onClick={() =>
              window.open('https://toolbox.riskeeper.com', '_blank')
            }
            size="small"
            active={invert}
            color="warning"
          >
            Login Toolbox
          </OutlinedButtonInverted>
        </NavActions>
      </NavContainer>
    </Header>
  );
};

export default HeaderNavigation;
